import React from 'react'
import T from 'prop-types'
import Markdown from 'react-markdown'
import styles from './Body.module.css'

const Body = props => (
  <Markdown className={styles.root} {...props}/>
)

Body.propTypes = {source: T.string.isRequired}

export default Body
