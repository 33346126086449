import React from 'react'
import styles from './ScrollUpButton.module.css'

export const scrollToY = (scrollTargetY = 0, speed = 2000) => {
  const scrollY = window.scrollY || document.documentElement.scrollTop
  const easing = 'easeOutSine'
  let currentTime = 0

  const time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8))

  const easingEquations = {easeOutSine: pos => Math.sin(pos * (Math.PI / 2))}

  const tick = () => {
    currentTime += 1 / 60
    const p = currentTime / time
    const t = easingEquations[easing](p)

    if (p < 1) {
      requestAnimationFrame(tick)

      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
    } else {
      window.scrollTo(0, scrollTargetY)
    }
  }

  tick()
}

export const icon = (
  <svg viewBox="0 0 18 30">
    <g strokeWidth="2" fill="none" fillRule="evenodd">
      <path d="M9 3v25.57" strokeLinecap="square"/>
      <path d="M1 10l8-8 8 8"/>
    </g>
  </svg>
)

export const ScrollUpButton = () => (
  <button type="button" className={styles.root} onClick={() => scrollToY()}>
    Наверх {icon}
  </button>
)

export default ScrollUpButton
