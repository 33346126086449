import PolicyPage from './PolicyPage'
import {graphql} from 'gatsby'

export default PolicyPage

export const pageQuery = graphql`
  query PolicyPageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "policy-page"}}) {
      rawMarkdownBody
      frontmatter {
        seo {
          title
          description
        }
      }
    }
  }
`
